var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box transport-box", class: { "p-3": _vm.onMap } },
    [
      _c("div", { staticClass: "level is-mobile mb-1" }, [
        _c("div", { staticClass: "level-left" }, [
          _c(
            "div",
            {
              staticClass: "level-item",
              staticStyle: { display: "inline-block" },
            },
            [
              _c("Emoji", {
                staticClass: "mr-2",
                attrs: {
                  utf: _vm.backmeta.transport[_vm.slug].icon,
                  size: _vm.onMap ? "20px" : "24px",
                },
              }),
              _c(
                "strong",
                {
                  class: { "is-size-6": _vm.onMap, "is-size-5": !_vm.onMap },
                  staticStyle: { "vertical-align": "18%" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.backmeta.transport[_vm.slug].name[_vm.currentLanguage]
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "level-item" },
            [
              _vm.isUserAuth && !_vm.userTransport[_vm.slug] && !_vm.onMap
                ? _c(
                    "b-tooltip",
                    {
                      staticClass: "has-text-centered",
                      attrs: {
                        type: "is-light",
                        triggers: ["click"],
                        "auto-close": ["outside", "escape"],
                        position: "is-left",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "content",
                            fn: function () {
                              return [
                                _c("p", [
                                  _vm._v(_vm._s(_vm.tt("TransportNotOwned"))),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.tt("TransportNotOwnedInfo"))
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1053235294
                      ),
                    },
                    [
                      _c(
                        "button",
                        { staticClass: "button is-success is-light" },
                        [_c("b-icon", { attrs: { icon: "web-refresh" } })],
                        1
                      ),
                    ]
                  )
                : _vm.isUserAuth &&
                  _vm.userTransport[_vm.slug] &&
                  _vm.userTransport[_vm.slug].state === "in_city" &&
                  !_vm.onMap
                ? _c(
                    "button",
                    {
                      key: _vm.userTransport[_vm.slug].state,
                      staticClass: "button is-rounded is-success is-light",
                      on: {
                        click: function ($event) {
                          return _vm.openCityOnMap(
                            _vm.userTransport[_vm.slug].city_id
                          )
                        },
                      },
                    },
                    [
                      _c("Emoji", {
                        key: "city",
                        staticClass: "mr-1 mt-2",
                        attrs: { utf: _vm.cityIcon, size: "20px" },
                      }),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.userTransport[_vm.slug].city_id) +
                            ". " +
                            _vm._s(
                              _vm.backmeta.cities[
                                _vm.userTransport[_vm.slug].city_id
                              ].nameObject[_vm.currentLanguage]
                            )
                        ),
                      ]),
                    ],
                    1
                  )
                : !_vm.isUserAuth
                ? _c(
                    "b-tooltip",
                    {
                      staticClass: "has-text-centered",
                      attrs: {
                        type: "is-light",
                        triggers: ["click"],
                        "auto-close": ["outside", "escape"],
                        position: "is-left",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _c("p", [_vm._v(_vm._s(_vm.tt("NeedTg")))]),
                              _c(
                                "a",
                                {
                                  attrs: { href: _vm.botUrl, target: "_blank" },
                                },
                                [_vm._v("@KarvanClubBot")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "button",
                        { staticClass: "button is-success is-light" },
                        [_c("b-icon", { attrs: { icon: "web-refresh" } })],
                        1
                      ),
                    ]
                  )
                : _vm.isUserAuth && _vm.transport[_vm.transportId]
                ? [
                    !_vm.onMap
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "button is-success is-light is-rounded",
                            on: {
                              click: function ($event) {
                                return _vm.openTransportOnMap(
                                  _vm.userTransport[_vm.slug].id
                                )
                              },
                            },
                          },
                          [
                            _c("Emoji", {
                              key: _vm.userTransport[_vm.slug].state,
                              staticClass: "mr-1 mt-2",
                              attrs: {
                                utf: [
                                  "accident",
                                  "evacuating_to_a",
                                  "evacuating_to_b",
                                ].includes(_vm.userTransport[_vm.slug].state)
                                  ? "🚨"
                                  : "🚀",
                                size: "20px",
                              },
                            }),
                            _c("span", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.transport[
                                      _vm.userTransport[_vm.slug].id
                                    ]
                                      ? _vm.transport[
                                          _vm.userTransport[_vm.slug].id
                                        ].speed
                                      : 0
                                  )
                                ),
                              ]),
                              _vm._v(" " + _vm._s(_vm.tt("kmh"))),
                            ]),
                          ],
                          1
                        )
                      : _c(
                          "span",
                          [
                            _c("Emoji", {
                              key: _vm.transport[_vm.transportId]
                                ? _vm.transport[_vm.transportId].state
                                : "ride",
                              staticClass: "mr-1",
                              attrs: {
                                utf:
                                  _vm.transport[_vm.transportId] &&
                                  [
                                    "accident",
                                    "evacuating_to_a",
                                    "evacuating_to_b",
                                  ].includes(
                                    _vm.transport[_vm.transportId].state
                                  )
                                    ? "🚨"
                                    : "🚀",
                                size: "20px",
                              },
                            }),
                            _c(
                              "span",
                              { staticStyle: { "vertical-align": "25%" } },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.transport[_vm.transportId]
                                        ? _vm.transport[_vm.transportId].speed
                                        : 0
                                    )
                                  ),
                                ]),
                                _vm._v(" " + _vm._s(_vm.tt("kmh"))),
                              ]
                            ),
                          ],
                          1
                        ),
                  ]
                : _c("button", { staticClass: "button is-ghost" }, [
                    _c("i", { staticClass: "mdi mdi-loading mdi-spin" }),
                  ]),
            ],
            2
          ),
        ]),
      ]),
      _vm.transportId && _vm.transport[_vm.transportId]
        ? _c(
            "div",
            { staticClass: "content" },
            [
              _c("article", { staticClass: "media mb-1" }, [
                _c("figure", { staticClass: "media-left image is-64x64 m-0" }, [
                  _c("img", {
                    staticClass: "is-rounded",
                    style: "border: 3px solid " + _vm.getUserColor(),
                    attrs: {
                      src: _vm.getUserPhoto(
                        _vm.transport[_vm.transportId].user_id
                      ),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openPhotoModal(
                          _vm.transport[_vm.transportId].user_id
                        )
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "media-content ml-2" }, [
                  _c(
                    "div",
                    {
                      staticClass: "content",
                      staticStyle: { height: "100%", overflow: "hidden" },
                    },
                    [
                      _c(
                        "p",
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getUserTitle(
                                  _vm.transport[_vm.transportId].user_id
                                )
                              ),
                            },
                          }),
                          _c("br"),
                          _vm.editTransportStatus
                            ? [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "word-break": "break-word" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.transport[_vm.transportId].status ||
                                          " . . . "
                                      )
                                    ),
                                  ]
                                ),
                                _vm.transport[_vm.transportId].user_id ==
                                _vm.user.id
                                  ? _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type: "is-ghost",
                                          size: "is-small",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editStatusDialog()
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "comment-edit-outline",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _c("button", { staticClass: "button is-ghost" }, [
                                _c("i", {
                                  staticClass: "mdi mdi-loading mdi-spin",
                                }),
                              ]),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
              ]),
              _c(
                "b-progress",
                {
                  attrs: {
                    type:
                      _vm.transport[_vm.transportId].state !== "accident"
                        ? "is-info"
                        : "is-danger",
                    rounded: false,
                    max: _vm.getRouteKm(_vm.transportId),
                    value: Number(_vm.transport[_vm.transportId].route_km),
                    size: "is-large",
                    "show-value": true,
                  },
                },
                [
                  _vm.transport[_vm.transportId].state !== "accident"
                    ? _c("i", { staticClass: "mdi mdi-loading mdi-spin mr-1" })
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(_vm.transport[_vm.transportId].route_km) +
                      " / " +
                      _vm._s(_vm.getRouteKm(_vm.transportId)) +
                      " " +
                      _vm._s(_vm.tt("km")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        : !_vm.onMap
        ? _c("div", { staticClass: "columns is-mobile" }, [
            _c("div", { staticClass: "column pr-0 is-9" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.backmeta.transport[_vm.slug].text[_vm.currentLanguage]
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "column is-size-6 pl-0 has-text-right" }, [
              _c(
                "p",
                [
                  _c("strong", { staticStyle: { "vertical-align": "15%" } }, [
                    _vm._v(_vm._s(_vm.backmeta.transport[_vm.slug].cargo)),
                  ]),
                  _c("b-icon", {
                    staticClass: "ml-1",
                    attrs: { icon: "treasure-chest" },
                  }),
                ],
                1
              ),
              _c(
                "p",
                [
                  _c(
                    "strong",
                    {
                      staticStyle: { "vertical-align": "15%" },
                      attrs: {
                        title: _vm.backmeta.transport[_vm.slug].rate100,
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.backmeta.transport[_vm.slug].tank) +
                          " "
                      ),
                    ]
                  ),
                  _c("b-icon", { attrs: { icon: "gas-station" } }),
                ],
                1
              ),
              _c(
                "p",
                [
                  _c("strong", { staticStyle: { "vertical-align": "15%" } }, [
                    _vm._v(_vm._s(_vm.backmeta.transport[_vm.slug].speed)),
                  ]),
                  _c("b-icon", {
                    staticClass: "ml-1",
                    attrs: { icon: "speedometer" },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }