var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-tabs",
    { staticStyle: { width: "330px" }, attrs: { position: "is-centered" } },
    [
      _c(
        "b-tab-item",
        { attrs: { icon: "account" } },
        [
          _c("article", { staticClass: "media mb-1" }, [
            _c(
              "figure",
              { staticClass: "media-left image is-64x64 m-0 pt-1" },
              [
                _c("img", {
                  staticClass: "is-rounded",
                  style:
                    "min-height: 48px; border: 3px solid " +
                    _vm.currentUserColor,
                  attrs: { src: _vm.getUserPhoto() },
                  on: {
                    click: function ($event) {
                      return _vm.openPhotoModal()
                    },
                  },
                }),
              ]
            ),
            _c("div", { staticClass: "media-content ml-2" }, [
              _c(
                "div",
                {
                  staticClass: "content",
                  staticStyle: { height: "100%", overflow: "hidden" },
                },
                [
                  _c(
                    "p",
                    { staticClass: "mb-0" },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.getUserTitle()) },
                      }),
                      _c("br"),
                      _c("b-icon", {
                        staticStyle: {
                          rotate: "45deg",
                          "margin-top": "0.1rem",
                        },
                        style: "color: " + _vm.currentUserColor,
                        attrs: { icon: "navigation" },
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "button is-small is-rounded mr-2 ml-1",
                          staticStyle: { color: "white" },
                          style: "background-color: " + _vm.selectedColor,
                          attrs: { disabled: _vm.isLoadingColor },
                          on: {
                            click: function ($event) {
                              _vm.isColorPickerOpened = !_vm.isColorPickerOpened
                            },
                          },
                        },
                        [
                          _c("strong", { staticClass: "is-uppercase" }, [
                            _vm._v(_vm._s(_vm.selectedColor)),
                          ]),
                        ]
                      ),
                      _vm.isLoadingColor
                        ? _c("i", { staticClass: "mdi mdi-loading mdi-spin" })
                        : _vm.currentUserColor !== _vm.user.color &&
                          !_vm.isLoadingUser
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "button is-small is-rounded is-success",
                              staticStyle: {
                                display: "inline-block",
                                "padding-top": "0.15rem",
                              },
                              attrs: {
                                title: _vm.tt("BuyColor"),
                                disabled:
                                  (_vm.colors[_vm.currentUserColor]
                                    ? _vm.colors[_vm.currentUserColor] * 2
                                    : 10) > _vm.user.gold,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.buyColor()
                                },
                              },
                            },
                            [
                              _c(
                                "strong",
                                { staticStyle: { "vertical-align": "30%" } },
                                [
                                  _vm._v(
                                    "-" +
                                      _vm._s(
                                        _vm.colors[_vm.currentUserColor]
                                          ? _vm.colors[_vm.currentUserColor] * 2
                                          : 10
                                      )
                                  ),
                                ]
                              ),
                              _c("Emoji", {
                                attrs: { utf: "💰", size: "18px" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "is-size-7 mt-1" }, [
                    _vm._v(_vm._s(_vm.tt("BookColorInfo"))),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm.isColorPickerOpened
            ? _c("b-colorpicker", {
                staticClass: "mt-2 has-text-centered",
                attrs: { value: _vm.selectedColor, inline: "" },
                model: {
                  value: _vm.selectedColor,
                  callback: function ($$v) {
                    _vm.selectedColor = $$v
                  },
                  expression: "selectedColor",
                },
              })
            : _vm._e(),
          _vm.user.reputationTotal > 0
            ? [
                _c(
                  "div",
                  { staticClass: "has-text-weight-semibold mt-2" },
                  [
                    _c("span", { staticStyle: { "vertical-align": "15%" } }, [
                      _vm._v(
                        " " + _vm._s(_vm.tt("InventoryReputation")) + " ("
                      ),
                    ]),
                    _c("Emoji", { attrs: { utf: "🤝", size: "20px" } }),
                    _c("span", { staticStyle: { "vertical-align": "15%" } }, [
                      _vm._v(" " + _vm._s(_vm.user.reputationTotal) + ") "),
                    ]),
                  ],
                  1
                ),
                _vm._l(
                  _vm.user.reputationObject,
                  function (cityReputation, cityId) {
                    return _c(
                      "p",
                      { key: cityId, staticStyle: { "line-height": "1" } },
                      [
                        _c(
                          "span",
                          { staticStyle: { "vertical-align": "30%" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.backmeta.cities[cityId].nameObject[
                                  _vm.currentLanguage
                                ]
                              )
                            ),
                          ]
                        ),
                        _c("Emoji", {
                          staticClass: "ml-1 mr-1",
                          attrs: { utf: "🤝", size: "20px" },
                        }),
                        _c(
                          "span",
                          { staticStyle: { "vertical-align": "30%" } },
                          [_vm._v(_vm._s(cityReputation))]
                        ),
                      ],
                      1
                    )
                  }
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "b-tab-item",
        { attrs: { icon: "cog" } },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: "",
                type: "is-black",
                message: _vm.tt("CenteredSwitchMessage"),
              },
            },
            [
              _c(
                "b-switch",
                {
                  model: {
                    value: _vm.navigationCenteredSwitch,
                    callback: function ($$v) {
                      _vm.navigationCenteredSwitch = $$v
                    },
                    expression: "navigationCenteredSwitch",
                  },
                },
                [_c("strong", [_vm._v(_vm._s(_vm.tt("CenteredSwitch")))])]
              ),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: "screensaver",
                type: "is-black",
                message: _vm.tt("ScreensaverMessage"),
              },
            },
            [
              _c(
                "b-radio-button",
                {
                  attrs: { "native-value": "0" },
                  model: {
                    value: _vm.screensaverDelayRadio,
                    callback: function ($$v) {
                      _vm.screensaverDelayRadio = $$v
                    },
                    expression: "screensaverDelayRadio",
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "close" } }),
                  _c("span", [_vm._v("OFF")]),
                ],
                1
              ),
              _c(
                "b-radio-button",
                {
                  attrs: { "native-value": "20" },
                  model: {
                    value: _vm.screensaverDelayRadio,
                    callback: function ($$v) {
                      _vm.screensaverDelayRadio = $$v
                    },
                    expression: "screensaverDelayRadio",
                  },
                },
                [_c("span", [_vm._v("20")])]
              ),
              _c(
                "b-radio-button",
                {
                  attrs: { "native-value": "40" },
                  model: {
                    value: _vm.screensaverDelayRadio,
                    callback: function ($$v) {
                      _vm.screensaverDelayRadio = $$v
                    },
                    expression: "screensaverDelayRadio",
                  },
                },
                [_c("span", [_vm._v("40")])]
              ),
              _c(
                "b-radio-button",
                {
                  attrs: { "native-value": "120" },
                  model: {
                    value: _vm.screensaverDelayRadio,
                    callback: function ($$v) {
                      _vm.screensaverDelayRadio = $$v
                    },
                    expression: "screensaverDelayRadio",
                  },
                },
                [_c("span", [_vm._v("120")])]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "columns is-mobile" }, [
            _c("div", { staticClass: "column mt-3 mr-2 is-size-5" }, [
              _c("a", { staticClass: "mr-4", attrs: { href: "/map" } }, [
                _vm.currentLanguage == "ua"
                  ? _c("strong", [_vm._v("UA")])
                  : _c("span", [_vm._v("UA")]),
              ]),
              _c("a", { staticClass: "mr-4", attrs: { href: "/en/map" } }, [
                _vm.currentLanguage == "en"
                  ? _c("strong", [_vm._v("EN")])
                  : _c("span", [_vm._v("EN")]),
              ]),
              _c("a", { attrs: { href: "/ru/map" } }, [
                _vm.currentLanguage == "ru"
                  ? _c("strong", [_vm._v("RU")])
                  : _c("span", [_vm._v("RU")]),
              ]),
            ]),
            _c("div", { staticClass: "column has-text-right" }, [
              _c(
                "button",
                {
                  staticClass: "button is-small is-rounded is-warning mt-2",
                  on: {
                    click: function ($event) {
                      return _vm.logoutUser()
                    },
                  },
                },
                [_c("b-icon", { attrs: { icon: "logout", title: "logout" } })],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-tab-item",
        {
          staticStyle: { "max-height": "500px", "overflow-y": "scroll" },
          attrs: { icon: "align-horizontal-left" },
        },
        _vm._l(_vm.actionLog, function (log, logId) {
          return _c(
            "div",
            {
              key: logId,
              staticClass: "mb-1",
              staticStyle: { "line-height": "0.8" },
            },
            [
              log.action === "buy"
                ? _c(
                    "div",
                    {
                      key: "buy",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "📥", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionItemBuy")) + " ")]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: _vm.cityIcon, size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(log.city_id) +
                                  ". " +
                                  _vm._s(
                                    _vm.backmeta.cities[log.city_id].nameObject[
                                      _vm.currentLanguage
                                    ]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v("-" + _vm._s(log.gold_diff))]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "💰", size: "18px" },
                          }),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: {
                              utf: _vm.goods[log.item_slug].emoji,
                              size: "18px",
                            },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.goods[log.item_slug].name[
                                    _vm.currentLanguage
                                  ]
                                )
                              ),
                            ]
                          ),
                          _c(
                            "strong",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(" +" + _vm._s(log.amount))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "sell"
                ? _c(
                    "div",
                    {
                      key: "sell",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "📤", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionItemSell")) + " ")]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: _vm.cityIcon, size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(log.city_id) +
                                  ". " +
                                  _vm._s(
                                    _vm.backmeta.cities[log.city_id].nameObject[
                                      _vm.currentLanguage
                                    ]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v("+" + _vm._s(log.gold_diff))]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "💰", size: "18px" },
                          }),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: {
                              utf: _vm.goods[log.item_slug].emoji,
                              size: "18px",
                            },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.goods[log.item_slug].name[
                                    _vm.currentLanguage
                                  ]
                                )
                              ),
                            ]
                          ),
                          _c(
                            "strong",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(" -" + _vm._s(log.amount))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "whIn"
                ? _c(
                    "div",
                    {
                      key: "whIn",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "📦", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionWhIn")) + " ")]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: _vm.cityIcon, size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(log.city_id) +
                                  ". " +
                                  _vm._s(
                                    _vm.backmeta.cities[log.city_id].nameObject[
                                      _vm.currentLanguage
                                    ]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: {
                              utf: _vm.goods[log.item_slug].emoji,
                              size: "18px",
                            },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.goods[log.item_slug].name[
                                    _vm.currentLanguage
                                  ]
                                )
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(" " + _vm._s(log.amount))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "whOut"
                ? _c(
                    "div",
                    {
                      key: "whOut",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🚘", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionWhOut")) + " ")]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: _vm.cityIcon, size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(log.city_id) +
                                  ". " +
                                  _vm._s(
                                    _vm.backmeta.cities[log.city_id].nameObject[
                                      _vm.currentLanguage
                                    ]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: {
                              utf: _vm.goods[log.item_slug].emoji,
                              size: "18px",
                            },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.goods[log.item_slug].name[
                                    _vm.currentLanguage
                                  ]
                                )
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(" " + _vm._s(log.amount))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "drive"
                ? _c(
                    "div",
                    {
                      key: "drive",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🚀", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionDrive")) + " ")]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: _vm.cityIcon, size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(log.city_id) +
                                  ". " +
                                  _vm._s(
                                    _vm.backmeta.cities[log.city_id].nameObject[
                                      _vm.currentLanguage
                                    ]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c("Emoji", {
                            attrs: {
                              utf: _vm.backmeta.transport[log.transport_slug]
                                .icon,
                              size: "18px",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "has-text-weight-medium",
                              staticStyle: { "vertical-align": "30%" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.backmeta.transport[log.transport_slug]
                                      .name[_vm.currentLanguage]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "in_city"
                ? _c(
                    "div",
                    {
                      key: "in_city",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🚘", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionInCity")) + " ")]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: _vm.cityIcon, size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(log.city_id) +
                                  ". " +
                                  _vm._s(
                                    _vm.backmeta.cities[log.city_id].nameObject[
                                      _vm.currentLanguage
                                    ]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c("Emoji", {
                            attrs: {
                              utf: _vm.backmeta.transport[log.transport_slug]
                                .icon,
                              size: "18px",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "has-text-weight-medium",
                              staticStyle: { "vertical-align": "30%" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.backmeta.transport[log.transport_slug]
                                      .name[_vm.currentLanguage]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "accident"
                ? _c(
                    "div",
                    {
                      key: "accident",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🚨", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionAccident")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          log.is_broke
                            ? _c("Emoji", {
                                staticClass: "mr-1",
                                attrs: { utf: "🔩", size: "18px" },
                              })
                            : _vm._e(),
                          log.out_of_gas
                            ? _c("Emoji", {
                                staticClass: "mr-1",
                                attrs: { utf: "⛽", size: "18px" },
                              })
                            : _vm._e(),
                          _c("Emoji", {
                            attrs: {
                              utf: _vm.backmeta.transport[log.transport_slug]
                                .icon,
                              size: "18px",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "has-text-weight-medium",
                              staticStyle: { "vertical-align": "30%" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.backmeta.transport[log.transport_slug]
                                      .name[_vm.currentLanguage]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "evacuate"
                ? _c(
                    "div",
                    {
                      key: "evacuate",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🚨", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionEvacuate")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v("-" + _vm._s(log.price))]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "💰", size: "18px" },
                          }),
                          _c("Emoji", {
                            attrs: {
                              utf: _vm.backmeta.transport[log.transport_slug]
                                .icon,
                              size: "18px",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "has-text-weight-medium",
                              staticStyle: { "vertical-align": "30%" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.backmeta.transport[log.transport_slug]
                                      .name[_vm.currentLanguage]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "fuel"
                ? _c(
                    "div",
                    {
                      key: "fuel",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "⛽", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionFuel")) + " ")]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: _vm.cityIcon, size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(log.city_id) +
                                  ". " +
                                  _vm._s(
                                    _vm.backmeta.cities[log.city_id].nameObject[
                                      _vm.currentLanguage
                                    ]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v("-" + _vm._s(log.price))]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "💰", size: "18px" },
                          }),
                          _c(
                            "strong",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v("100%")]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "⛽", size: "18px" },
                          }),
                          _c("Emoji", {
                            attrs: {
                              utf: _vm.backmeta.transport[log.transport_slug]
                                .icon,
                              size: "18px",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "has-text-weight-medium",
                              staticStyle: { "vertical-align": "30%" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.backmeta.transport[log.transport_slug]
                                      .name[_vm.currentLanguage]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "repair"
                ? _c(
                    "div",
                    {
                      key: "repair",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🔧️", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionRepair")) + " ")]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: _vm.cityIcon, size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(log.city_id) +
                                  ". " +
                                  _vm._s(
                                    _vm.backmeta.cities[log.city_id].nameObject[
                                      _vm.currentLanguage
                                    ]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v("-" + _vm._s(log.price))]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "💰", size: "18px" },
                          }),
                          _c(
                            "strong",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v("100%")]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🔩", size: "18px" },
                          }),
                          _c("Emoji", {
                            attrs: {
                              utf: _vm.backmeta.transport[log.transport_slug]
                                .icon,
                              size: "18px",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "has-text-weight-medium",
                              staticStyle: { "vertical-align": "30%" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.backmeta.transport[log.transport_slug]
                                      .name[_vm.currentLanguage]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "contractApply"
                ? _c(
                    "div",
                    {
                      key: "contractApply",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🤝", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionApply")) + " ")]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: _vm.cityIcon, size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(log.city_id) +
                                  ". " +
                                  _vm._s(
                                    _vm.backmeta.cities[log.city_id].nameObject[
                                      _vm.currentLanguage
                                    ]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "has-text-weight-medium",
                              staticStyle: { "vertical-align": "30%" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(log.city_id) +
                                  "." +
                                  _vm._s(log.contract_id) +
                                  " "
                              ),
                            ]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🤝", size: "18px" },
                          }),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "👍", size: "18px" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "contractDone"
                ? _c(
                    "div",
                    {
                      key: "contractDone",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🏆", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionDone")) + " ")]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: _vm.cityIcon, size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(log.city_id) +
                                  ". " +
                                  _vm._s(
                                    _vm.backmeta.cities[log.city_id].nameObject[
                                      _vm.currentLanguage
                                    ]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "has-text-weight-medium",
                              staticStyle: { "vertical-align": "30%" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(log.city_id) +
                                  "." +
                                  _vm._s(log.contract_id) +
                                  " "
                              ),
                            ]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🏆", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v("+" + _vm._s(log.prize_rep))]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🤝", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v("+" + _vm._s(log.prize_gold))]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "💰", size: "18px" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "contractBreak"
                ? _c(
                    "div",
                    {
                      key: "contractBreak",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "❌", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionBreak")) + " ")]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: _vm.cityIcon, size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(log.city_id) +
                                  ". " +
                                  _vm._s(
                                    _vm.backmeta.cities[log.city_id].nameObject[
                                      _vm.currentLanguage
                                    ]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "has-text-weight-medium",
                              staticStyle: { "vertical-align": "30%" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(log.city_id) +
                                  "." +
                                  _vm._s(log.contract_id) +
                                  " "
                              ),
                            ]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "❌", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v("-" + _vm._s(log.penalty_rep))]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🤝", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v("-" + _vm._s(log.penalty_gold))]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "💰", size: "18px" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "addTransport"
                ? _c(
                    "div",
                    {
                      key: "addTransport",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🚘", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionAddTransport")) + " ")]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: _vm.cityIcon, size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [
                              _vm._v(
                                _vm._s(log.city_id) +
                                  ". " +
                                  _vm._s(
                                    _vm.backmeta.cities[log.city_id].nameObject[
                                      _vm.currentLanguage
                                    ]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c("Emoji", {
                            attrs: {
                              utf: _vm.backmeta.transport[log.transport_slug]
                                .icon,
                              size: "18px",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "has-text-weight-medium",
                              staticStyle: { "vertical-align": "30%" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.backmeta.transport[log.transport_slug]
                                      .name[_vm.currentLanguage]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "addGold"
                ? _c(
                    "div",
                    {
                      key: "addGold",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "💰", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionAddGold")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "has-text-weight-medium",
                              staticStyle: { "vertical-align": "30%" },
                            },
                            [_vm._v("+" + _vm._s(log.gold))]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "💰", size: "18px" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "donateGold"
                ? _c(
                    "div",
                    {
                      key: "donateGold",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🍩", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionDonateGold")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "has-text-weight-medium",
                              staticStyle: { "vertical-align": "30%" },
                            },
                            [_vm._v("+" + _vm._s(log.gold))]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "💰", size: "18px" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "setColor"
                ? _c(
                    "div",
                    {
                      key: "setColor",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "💄", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionSetColor")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "has-text-weight-medium",
                              staticStyle: { "vertical-align": "30%" },
                            },
                            [_vm._v("-" + _vm._s(log.price))]
                          ),
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "💰", size: "18px" },
                          }),
                          _c(
                            "span",
                            {
                              staticClass:
                                "has-text-weight-medium p-0 pr-2 pl-1 is-size-6",
                              staticStyle: {
                                "vertical-align": "30%",
                                color: "white",
                                "border-radius": "10px",
                              },
                              style: "background-color: #" + log.color,
                            },
                            [_vm._v(" #" + _vm._s(log.color) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : log.action === "setStatus"
                ? _c(
                    "div",
                    {
                      key: "setStatus",
                      staticStyle: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-medium" },
                        [
                          _c("Emoji", {
                            staticClass: "mr-1",
                            attrs: { utf: "🗣️", size: "18px" },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "vertical-align": "30%" } },
                            [_vm._v(_vm._s(_vm.tt("ActionSetStatus")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "small",
                            { staticStyle: { "vertical-align": "38%" } },
                            [
                              _vm._v(
                                _vm._s(
                                  new Date(log.ts * 1000).toLocaleTimeString(
                                    "uk-UA"
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c("Emoji", {
                            attrs: {
                              utf: _vm.backmeta.transport[log.transport_slug]
                                .icon,
                              size: "18px",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "has-text-weight-medium",
                              staticStyle: { "vertical-align": "30%" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.backmeta.transport[log.transport_slug]
                                      .name[_vm.currentLanguage]
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }