<template>
    <div class="box transport-box" :class="{'p-3': onMap}">
        <div class="level is-mobile mb-1">
            <div class="level-left">
                <div class="level-item" style="display: inline-block;">
                    <Emoji class="mr-2" :utf="backmeta.transport[slug].icon" :size="onMap ? '20px' : '24px'" />
                    <strong style="vertical-align: 18%" :class="{'is-size-6': onMap, 'is-size-5': !onMap}">{{ backmeta.transport[slug].name[currentLanguage]}}</strong>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <b-tooltip
                        v-if="isUserAuth && !userTransport[slug] && !onMap"
                        type="is-light"
                        :triggers="['click']"
                        :auto-close="['outside', 'escape']"
                        class="has-text-centered"
                        position="is-left"
                    >
                        <template v-slot:content>
                            <p>{{  tt('TransportNotOwned') }}</p>
                            <p>{{  tt('TransportNotOwnedInfo') }}</p>
                        </template>
                        <button class="button is-success is-light">
                            <b-icon
                                icon="web-refresh"
                            />
                        </button>
                    </b-tooltip>
                    <button 
                        v-else-if="isUserAuth && userTransport[slug] && userTransport[slug].state === 'in_city' && !onMap"
                        class="button is-rounded is-success is-light"
                        @click="openCityOnMap(userTransport[slug].city_id)"
                        :key="userTransport[slug].state"
                    >
                        <Emoji class="mr-1 mt-2" :utf="cityIcon" size="20px" key="city"/> 
                        <strong>{{ userTransport[slug].city_id }}. {{ backmeta.cities[userTransport[slug].city_id].nameObject[currentLanguage]}}</strong>
                    </button>
                    <b-tooltip
                        v-else-if="!isUserAuth"
                        type="is-light"
                        :triggers="['click']"
                        :auto-close="['outside', 'escape']"
                        class="has-text-centered"
                        position="is-left"
                    >
                        <template v-slot:content>
                            <p>{{  tt('NeedTg') }}</p>
                            <a :href="botUrl" target="_blank">@KarvanClubBot</a>
                        </template>
                        <button class="button is-success is-light">
                            <b-icon
                                icon="web-refresh"
                            />
                        </button>
                    </b-tooltip>
                    <template v-else-if="isUserAuth && transport[transportId]">
                        <button 
                            v-if="!onMap"
                            class="button is-success is-light is-rounded"
                            @click="openTransportOnMap(userTransport[slug].id)"
                        >
                            <Emoji
                                class="mr-1 mt-2"
                                :utf="['accident', 'evacuating_to_a', 'evacuating_to_b'].includes(userTransport[slug].state) ? '🚨' : '🚀'"
                                size="20px"
                                :key="userTransport[slug].state"
                            />
                            <span><strong>{{ transport[userTransport[slug].id] ? transport[userTransport[slug].id].speed : 0 }}</strong> {{ tt('kmh') }}</span>
                        </button>
                        <span v-else>
                            <Emoji
                                class="mr-1"
                                :utf="(transport[transportId] && ['accident', 'evacuating_to_a', 'evacuating_to_b'].includes(transport[transportId].state)) ? '🚨' : '🚀'"
                                size="20px"
                                :key="transport[transportId] ? transport[transportId].state : 'ride'"
                            />
                            <span style="vertical-align: 25%;"><strong>{{ transport[transportId] ? transport[transportId].speed : 0 }}</strong> {{ tt('kmh') }}</span>
                        </span>
                    </template>
                    <button
                        v-else
                        class="button is-ghost"
                    >
                        <i class="mdi mdi-loading mdi-spin"></i>
                    </button>
                </div>
            </div>
        </div>
        <div
            v-if="transportId && transport[transportId]"
            class="content"
        >
            <article class="media mb-1">
                <figure class="media-left image is-64x64 m-0">
                    <img
                        @click="openPhotoModal(transport[transportId].user_id)"
                        class="is-rounded"
                        :src="getUserPhoto(transport[transportId].user_id)"
                        :style="'border: 3px solid ' + getUserColor()"
                    />
                </figure>
                <div class="media-content ml-2">
                    <div class="content" style="height: 100%; overflow: hidden">
                        <p>
                            <span v-html="getUserTitle(transport[transportId].user_id)"></span>
                            <br />
                            <template v-if="editTransportStatus">
                                <span style="word-break: break-word;">{{ transport[transportId].status || ' . . . ' }}</span>
                                <b-button v-if="transport[transportId].user_id == user.id" @click="editStatusDialog()" type="is-ghost" size="is-small" class="">
                                    <b-icon icon="comment-edit-outline"/>
                                </b-button>
                            </template>
                            <button
                                v-else
                                class="button is-ghost"
                            >
                                <i class="mdi mdi-loading mdi-spin"></i>
                            </button>
                        </p>
                    </div>
                </div>
            </article>
            <b-progress
                :type="transport[transportId].state !== 'accident' ? 'is-info' : 'is-danger'"
                :rounded="false"
                :max="getRouteKm(transportId)"
                :value="Number(transport[transportId].route_km)" 
                size="is-large"
                :show-value="true"
                class=""
            >
                <i v-if="transport[transportId].state !== 'accident'" class="mdi mdi-loading mdi-spin mr-1"></i>
                {{ transport[transportId].route_km }} / {{ getRouteKm(transportId) }} {{ tt('km') }}
            </b-progress>
        </div>
        <div v-else-if="!onMap" class="columns is-mobile">
            <div class="column pr-0 is-9">
                {{ backmeta.transport[slug].text[currentLanguage]}}
            </div>
            <div class="column is-size-6 pl-0 has-text-right">
                <p>
                    <strong style="vertical-align: 15%;" class="">{{ backmeta.transport[slug].cargo }}</strong>
                    <b-icon icon="treasure-chest" class="ml-1" />
                </p>
                <p>
                    <strong style="vertical-align: 15%;" class="" :title="backmeta.transport[slug].rate100">
                        {{ backmeta.transport[slug].tank }}
                    </strong>
                    <b-icon icon="gas-station" class="" />
                </p>
                <p>
                    <strong style="vertical-align: 15%;" class="">{{ backmeta.transport[slug].speed }}</strong>
                    <b-icon icon="speedometer" class="ml-1" />
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    // import Vue from 'vue';
    import tt from './../plugins/tt.js';

    import Emoji from './../widgets/Emoji.vue';

    export default {
        components: {
            Emoji
        },
        props: {
            slug: {
                type: String,
                required: true
            },
            transportId: {
                type: String,
                required: false
            },
            botUrl: {
                type: String,
                required: true
            },
            onMap: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                tt: tt,
                currentLanguage: 'ua',
                isUserAuth: false,
                editTransportStatus: true,
            }
        },
        computed: mapState({
            backmeta: 'backmeta',
            placeholder: 'placeholder',
            authToken: 'authToken',
            cityIcon: 'ws.CityIcon',
            user: 'ws.User',
            users: 'ws.Users',
            userTransport: 'ws.UserTransport',
            transport: 'ws.Transport',
            routes: 'ws.Routes',
            colors: 'ws.Colors'
        }),
        watch: {
            authToken: function (newAuthToken) {
                this.isUserAuth = newAuthToken ? true : false;
            },
            transport: function () {
                this.editTransportStatus = true;
            }
        },
        created () {
            this.currentLanguage = localStorage.getItem('lang') || 'ua';
            if (this.authToken) {
                this.isUserAuth = true;
            }
        },
        methods: {
            openCityOnMap(cityId) {
                if (this.currentLanguage === 'ua') {
                    window.history.replaceState(null, null, '/map');
                } else {
                    window.history.replaceState(null, null, '/' + this.currentLanguage + '/map');
                }

                this.$store.commit('setCurrentWindow', 'map');
                setTimeout(() => {
                    this.$store.commit('openCityOnMap', cityId);
                }, 500);
            },
            openTransportOnMap(transportId) {
                if (this.currentLanguage === 'ua') {
                    window.history.replaceState(null, null, '/map');
                } else {
                    window.history.replaceState(null, null, '/' + this.currentLanguage + '/map');
                }

                this.$store.commit('openTransportOnMap', transportId);
                this.$store.commit('setCurrentWindow', 'map');
            },
            getRouteKm(transportId) {
                const mapTransport = this.transport[transportId];

                let direction = 'km_to_a';
                if (mapTransport.state === 'to_b' || mapTransport.state === 'evacuating_to_b') {
                    direction = 'km_to_b';
                }

                return Number(this.routes[mapTransport.route_id][direction]);
            },
            getUserPhoto(userId) {
                if (!this.users[userId]) {
                    return this.placeholder;
                }

                const userPhotos = this.users[userId].photosObject || null;
                if (!userPhotos || !userPhotos[0]) {
                    return this.placeholder;
                }

                const cdn = process.env.VUE_APP_CDN_URL || 'https://sultan-cdn.karvan.club';

                return cdn + '/' + userPhotos[0];
            },
            getUserTitle(userId) {
                if (!this.users[userId]) {
                    return '<strong>John Smith</strong> <small>@johnsmith</small>';
                }
                const user = this.users[userId];

                let userTitle = `<strong>${user.first_name} ${user.last_name}</strong>`;
                const npcs = ['pit', 'mad', 'egor'];
                if (!user.is_annon && !npcs.includes(user.username)) {
                    userTitle += ` <small><a target="_blank" href="https://t.me/${user.username}">@${user.username}</a></small>`;
                } else {
                    userTitle += ` <small>@${user.username}</small>`;
                }
                return userTitle;
            },
            editStatusDialog() {
                this.$buefy.dialog.prompt({
                    inputAttrs: {
                        type: "text",
                        placeholder: "e.g. Walter",
                        maxlength: 255
                    },
                    confirmText: '📨',
                    cancelText: '❌',
                    trapFocus: true,
                    onConfirm: value => {
                        this.callEditStatus(value);
                    }
                });
            },
            callEditStatus (status) {
                this.$wsCall('SetStatus', {
                    transportId: this.userTransport[this.slug].id,
                    status: status
                });

                this.editTransportStatus = false;
            },
            openPhotoModal(userId) {
                const user = this.users[userId];
                if (!user) {
                    return;
                }

                const cdn = process.env.VUE_APP_CDN_URL || 'https://sultan-cdn.karvan.club';
                const userPhotos = user.photosObject || [];
                let photoIndex = 1;

                const img = this.$createElement('img', {
                    ref: 'userPhotoGallery',
                    attrs: {
                        src: userPhotos[0] ? (cdn + '/' + userPhotos[0]) : this.placeholder,
                    },
                    on: {
                        click: () => {
                            this.$refs['userPhotoGallery'].src = userPhotos[photoIndex] ? (cdn + '/' + userPhotos[photoIndex]) : this.placeholder;

                            photoIndex++;
                            if (!userPhotos[photoIndex]) {
                                photoIndex = 0;
                            }
                        }
                    }
                });

                this.$buefy.modal.open({
                    content: [ img ],
                    width: 640
                })
            },
            getUserColor() {
                let user = this.user;
                if (this.transportId) {
                    const userId = this.transport[this.transportId].user_id;
                    user = this.users[userId];
                    if (!user) {
                        return this.stringToColor(userId);
                    }
                }

                if (user.color) {
                    return user.color;
                }

                let userColor = this.stringToColor(user.first_name + user.last_name);
                if (!this.colors[userColor]) {
                    return userColor;
                }

                let i = 0;
                do {
                    userColor = this.stringToColor(userColor + i);
                    i = i + 1;
                } while (this.colors[userColor] && i < 1000);

                return userColor;
            },
            stringToColor (str) {
                str = 'AAA AAA' + str;

                let hash = 0;
                for (let i = 0; i < str.length; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash);
                }

                let color = '#';
                for (let i = 0; i < 3; i++) {
                    let value = (hash >> (i * 8)) & 0xFF;
                    color += ('00' + value.toString(16)).substr(-2);
                }

                return color.toUpperCase();
            },
        }
    }
</script>
