var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "l-map",
        {
          ref: "myMap",
          style: "width: 100%; height: " + _vm.windowHeight + "px",
          attrs: {
            zoom: _vm.mapCurrentZoom,
            center: _vm.mapCurrentCenter,
            minZoom: _vm.mapOptions.minZoom,
            maxZoom: _vm.mapOptions.maxZoom,
            options: {
              zoomControl: false,
            },
          },
        },
        [
          _c("l-tile-layer", {
            attrs: {
              url: _vm.tileLayerUrl,
              attribution: _vm.tileLayerAttribution,
              options: _vm.tileOptions,
            },
          }),
          _vm._l(_vm.backmeta.cities, function (city, cityId) {
            return _c(
              "l-marker",
              {
                key: "cities-" + cityId,
                ref: "city" + cityId,
                refInFor: true,
                attrs: {
                  "lat-lng": [parseFloat(city.lat), parseFloat(city.lon)],
                },
                on: {
                  click: function ($event) {
                    return _vm.cityMarkerClickOnMarker(cityId)
                  },
                },
              },
              [
                _c(
                  "l-popup",
                  { attrs: { options: { minWidth: 380 } } },
                  [
                    _c("City", {
                      key: "cityElement" + cityId,
                      attrs: {
                        cityId: cityId,
                        botUrl: _vm.getBotUrl(),
                        showMarketCarousel: cityId === _vm.lastOpenCity,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._l(_vm.currentTransportMapState, function (item, transportId) {
            return _c(
              "l-marker",
              {
                key: "transportMarker" + transportId,
                ref: transportId,
                refInFor: true,
                attrs: {
                  "lat-lng": _vm.currentTransportMapState[transportId].latLng,
                  icon: _vm.getTransportIcon(transportId, item),
                  options: {
                    transportId: transportId,
                    transportColor: _vm.getUserColor(item.user_id),
                  },
                },
                on: {
                  click: function ($event) {
                    return _vm.transportMarkerClickOnMarker(transportId)
                  },
                },
              },
              [
                _c(
                  "l-popup",
                  {
                    attrs: {
                      options: {
                        offset: [0, -36],
                        autoPan: false,
                        closeButton: false,
                        closeOnClick: false,
                        autoClose: false,
                        maxWidth: 350,
                        minWidth: 350,
                      },
                    },
                  },
                  [
                    _c("Transport", {
                      staticClass: "is-family-sans-serif",
                      attrs: {
                        slug: item.slug,
                        transportId: transportId,
                        botUrl: _vm.getBotUrl(),
                        onMap: true,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm.displayPath.length > 0
            ? _c("l-polyline", {
                attrs: {
                  "lat-lngs": _vm.displayPath,
                  color: "#205df7",
                  weight: 5,
                  opacity: 0.7,
                },
              })
            : _vm._e(),
        ],
        2
      ),
      _c(
        "b-button",
        {
          staticClass: "pt-2",
          class: {
            "is-success": !_vm.isUserOpen,
            "is-primary": _vm.isUserOpen,
          },
          staticStyle: {
            position: "absolute",
            right: "10px",
            top: "16px",
            "z-index": "1000",
          },
          attrs: { type: "is-rounded" },
          on: {
            click: function ($event) {
              _vm.isUserOpen = !_vm.isUserOpen
            },
          },
        },
        [
          _c(
            "span",
            {
              key: _vm.user ? _vm.user.gold : null,
              staticClass: "is-size-4",
              staticStyle: { "vertical-align": "10%" },
            },
            [_vm._v(_vm._s(_vm.isUserOpen ? _vm.user.gold : _vm.getUserGold()))]
          ),
          _c("Emoji", {
            staticClass: "ml-1",
            attrs: { utf: "💰", size: "22px" },
          }),
        ],
        1
      ),
      _vm.isUserOpen
        ? _c(
            "div",
            {
              staticClass: "box p-2",
              staticStyle: {
                position: "absolute",
                right: "10px",
                top: "70px",
                "z-index": "1000",
              },
            },
            [_c("User")],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            left: "20px",
            top: "80px",
            "z-index": "1000",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary is-light is-rounded is-medium" },
                  on: {
                    click: function ($event) {
                      return _vm.zoomReset()
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.mapCurrentZoom == _vm.mapOptions.minZoom
                          ? "M"
                          : _vm.mapCurrentZoom == _vm.mapOptions.maxZoom
                          ? "m"
                          : _vm.mapCurrentZoom
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    type: "is-primary is-light is-rounded is-medium",
                    disabled: _vm.mapCurrentZoom == _vm.mapOptions.maxZoom,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.zoomIn()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "icon" }, [
                    _c("i", { staticClass: "mdi mdi-18px mdi-plus-thick" }),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    type: "is-primary is-light is-rounded is-medium",
                    disabled: _vm.mapCurrentZoom == _vm.mapOptions.minZoom,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.zoomOut()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "icon" }, [
                    _c("i", { staticClass: "mdi mdi-18px mdi-minus-thick" }),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "columns has-text-right mb-0",
          staticStyle: {
            position: "absolute",
            right: "10px",
            bottom: "25px",
            "z-index": "1000",
          },
        },
        [
          _vm._l(_vm.userTransport, function (userTransportId) {
            return _c(
              "div",
              { key: userTransportId, staticClass: "column" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "pt-3",
                    class: {
                      "is-warning":
                        userTransportId in _vm.isUserTransportSpeedUp &&
                        _vm.isUserTransportSpeedUp[userTransportId],
                      "is-danger is-outlined":
                        userTransportId in _vm.isUserTransportSpeedUp &&
                        !_vm.isUserTransportSpeedUp[userTransportId],
                    },
                    staticStyle: { transition: "500ms linear" },
                    attrs: {
                      type: _vm.getUserTransportButtonType(userTransportId),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.transportMarkerClickOnButton(userTransportId)
                      },
                    },
                  },
                  [
                    _vm.isKmh
                      ? _c(
                          "strong",
                          { staticStyle: { "vertical-align": "15%" } },
                          [_vm._v(_vm._s(_vm.transport[userTransportId].speed))]
                        )
                      : _c(
                          "strong",
                          { staticStyle: { "vertical-align": "15%" } },
                          [
                            _vm._v(
                              _vm._s(_vm.transport[userTransportId].route_km)
                            ),
                          ]
                        ),
                    _c("Emoji", {
                      staticClass: "ml-2",
                      attrs: {
                        utf: _vm.backmeta.transport[
                          _vm.transport[userTransportId].slug
                        ].icon,
                        size: "20px",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "b-button",
                {
                  staticClass: "is-rounded",
                  class: {
                    "is-success": !_vm.isTransportLoading,
                    "is-info": _vm.isTransportLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.clickLoadingButton()
                    },
                  },
                },
                [
                  _vm.isTransportLoading
                    ? _c("i", { staticClass: "mdi mdi-loading mdi-spin" })
                    : _vm.isScreensaverActive
                    ? _c("i", { staticClass: "mdi mdi-play" })
                    : _c("strong", [
                        _vm._v(
                          _vm._s(_vm.isKmh ? _vm.tt("kmh") : _vm.tt("km"))
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.selectedTransport &&
      _vm.currentTransportMapState[_vm.selectedTransport]
        ? _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                left: "10px",
                bottom: "25px",
                "z-index": "1000",
              },
              on: {
                click: function ($event) {
                  return _vm.openCurrentTransportCity()
                },
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "button is-rounded is-light is-info mb-1",
                  staticStyle: { display: "inline-block" },
                },
                [
                  _c("Emoji", {
                    staticClass: "mr-1",
                    attrs: { utf: "🚀", size: "20px" },
                  }),
                  _c("Emoji", {
                    staticClass: "mr-1",
                    attrs: { utf: _vm.cityIcon, size: "20px" },
                  }),
                  _c("span", { staticStyle: { "vertical-align": "22%" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.currentTransportMapState[_vm.selectedTransport]
                            .city_id
                        ) +
                        ". " +
                        _vm._s(_vm.getCityNameFromSelectedTransport()) +
                        " "
                    ),
                  ]),
                  _c("Emoji", {
                    staticClass: "ml-1",
                    attrs: {
                      utf: _vm.getCityWeatherFromSelectedTransport()?.icon,
                      size: "20px",
                    },
                  }),
                  _c("span", { staticStyle: { "vertical-align": "22%" } }, [
                    _vm._v(
                      _vm._s(_vm.getCityWeatherFromSelectedTransport()?.temp) +
                        "°C"
                    ),
                  ]),
                ],
                1
              ),
              _c("p", { staticClass: "has-text-centered has-text-primary" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.getEstimatedKm(_vm.selectedTransport)) +
                      " " +
                      _vm._s(_vm.tt("km")) +
                      " "
                  ),
                ]),
                _c("strong", [
                  _vm._v(
                    "~" + _vm._s(_vm.getEstimatedTime(_vm.selectedTransport))
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.debugToken
        ? _c(
            "b-button",
            {
              staticStyle: {
                position: "absolute",
                left: "20px",
                top: "300px",
                "z-index": "1000",
              },
              attrs: { type: "is-danger is-rounded is-medium" },
              on: {
                click: function ($event) {
                  _vm.isDebugModal = true
                },
              },
            },
            [_c("b-icon", { attrs: { pack: "mdi", icon: "bug" } })],
            1
          )
        : _vm._e(),
      _vm.debugToken
        ? _c(
            "b-modal",
            {
              staticStyle: { "z-index": "1000" },
              attrs: { width: 640 },
              model: {
                value: _vm.isDebugModal,
                callback: function ($$v) {
                  _vm.isDebugModal = $$v
                },
                expression: "isDebugModal",
              },
            },
            [
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  { staticClass: "card-content" },
                  [
                    _c(
                      "b-field",
                      [
                        _c(
                          "b-switch",
                          {
                            model: {
                              value: _vm.isDebugEnable,
                              callback: function ($$v) {
                                _vm.isDebugEnable = $$v
                              },
                              expression: "isDebugEnable",
                            },
                          },
                          [_vm._v("Is debug enable")]
                        ),
                        _c(
                          "b-switch",
                          {
                            model: {
                              value: _vm.isDebugStopAnimation,
                              callback: function ($$v) {
                                _vm.isDebugStopAnimation = $$v
                              },
                              expression: "isDebugStopAnimation",
                            },
                          },
                          [_vm._v("Stop animation")]
                        ),
                        _c(
                          "b-switch",
                          {
                            model: {
                              value: _vm.isDebugStopTransition,
                              callback: function ($$v) {
                                _vm.isDebugStopTransition = $$v
                              },
                              expression: "isDebugStopTransition",
                            },
                          },
                          [_vm._v("Stop transition")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-field",
                      [
                        _c(
                          "b-switch",
                          {
                            model: {
                              value: _vm.isDebugStopTransportUpdate,
                              callback: function ($$v) {
                                _vm.isDebugStopTransportUpdate = $$v
                              },
                              expression: "isDebugStopTransportUpdate",
                            },
                          },
                          [_vm._v("Stop transport update")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-tabs",
                      { attrs: { position: "is-centered" } },
                      [
                        _c(
                          "b-tab-item",
                          { attrs: { label: "Debug Route" } },
                          [
                            _c(
                              "b-field",
                              { attrs: { label: "City A" } },
                              [
                                _c(
                                  "b-select",
                                  {
                                    attrs: { placeholder: "Select from city" },
                                    model: {
                                      value: _vm.debugCityA,
                                      callback: function ($$v) {
                                        _vm.debugCityA = $$v
                                      },
                                      expression: "debugCityA",
                                    },
                                  },
                                  _vm._l(_vm.cities, function (city, cityId) {
                                    return _c(
                                      "option",
                                      {
                                        key: cityId,
                                        domProps: { value: cityId },
                                      },
                                      [_vm._v(" " + _vm._s(city.name) + " ")]
                                    )
                                  }),
                                  0
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { label: "City B" } },
                              [
                                _c(
                                  "b-select",
                                  {
                                    attrs: { placeholder: "Select from city" },
                                    model: {
                                      value: _vm.debugCityB,
                                      callback: function ($$v) {
                                        _vm.debugCityB = $$v
                                      },
                                      expression: "debugCityB",
                                    },
                                  },
                                  _vm._l(_vm.cities, function (city, cityId) {
                                    return _c(
                                      "option",
                                      {
                                        key: cityId,
                                        domProps: { value: cityId },
                                      },
                                      [_vm._v(" " + _vm._s(city.name) + " ")]
                                    )
                                  }),
                                  0
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "has-text-right" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.debugRoute()
                                      },
                                    },
                                  },
                                  [_vm._v("Debug route")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { label: "JSON path" } },
                              [
                                _c("b-input", {
                                  attrs: { type: "textarea" },
                                  model: {
                                    value: _vm.debugJsonPath,
                                    callback: function ($$v) {
                                      _vm.debugJsonPath = $$v
                                    },
                                    expression: "debugJsonPath",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-tab-item",
                          { attrs: { label: "Render Route" } },
                          [
                            _c(
                              "b-field",
                              { attrs: { label: "Route ID" } },
                              [
                                _c(
                                  "b-select",
                                  {
                                    attrs: { placeholder: "Select route" },
                                    model: {
                                      value: _vm.debugRouteId,
                                      callback: function ($$v) {
                                        _vm.debugRouteId = $$v
                                      },
                                      expression: "debugRouteId",
                                    },
                                  },
                                  _vm._l(_vm.routes, function (route, routeId) {
                                    return _c(
                                      "option",
                                      {
                                        key: routeId,
                                        domProps: { value: routeId },
                                      },
                                      [_vm._v(" " + _vm._s(routeId) + " ")]
                                    )
                                  }),
                                  0
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-switch",
                                  {
                                    model: {
                                      value: _vm.debugIsToA,
                                      callback: function ($$v) {
                                        _vm.debugIsToA = $$v
                                      },
                                      expression: "debugIsToA",
                                    },
                                  },
                                  [_vm._v("Is to A")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.debugRenderRoute()
                                  },
                                },
                              },
                              [_vm._v("Render route")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-tab-item",
                          { attrs: { label: "Values" } },
                          [
                            _c(
                              "b-field",
                              { attrs: { label: "Force rotation" } },
                              [
                                _c("b-input", {
                                  model: {
                                    value: _vm.debugForceRotation,
                                    callback: function ($$v) {
                                      _vm.debugForceRotation = $$v
                                    },
                                    expression: "debugForceRotation",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("CityModal", { staticStyle: { "z-index": "1000" } }),
      _vm.isDebugEnable
        ? _c("notifications", {
            attrs: { group: "main", position: "bottom right" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }