var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    _vm.currentWindow === "landing"
      ? _c("div", { staticStyle: { "min-width": "460px" } }, [_c("Landing")], 1)
      : _vm._e(),
    _vm.currentWindow === "map"
      ? _c(
          "div",
          [
            _c(
              "button",
              {
                staticClass: "button is-rounded is-primary is-medium",
                staticStyle: {
                  position: "absolute",
                  left: "10px",
                  top: "10px",
                  "z-index": "1000",
                },
                on: {
                  click: function ($event) {
                    return _vm.openLanding()
                  },
                },
              },
              [
                _c("Emoji", { staticClass: "mr-2 mt-1", attrs: { utf: "🐫" } }),
                _c("strong", [_vm._v("KarVan.Club")]),
              ],
              1
            ),
            _c("Map"),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }