var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box" }, [
    _c(
      "div",
      {
        staticClass: "level is-mobile",
        class: { "mb-3": _vm.botUrl, "mb-0": !_vm.botUrl },
      },
      [
        _c("div", { staticClass: "level-left" }, [
          _c(
            "div",
            { staticClass: "level-item" },
            [
              _vm.isUserAuth && _vm.botUrl
                ? _c(
                    "button",
                    {
                      staticClass: "button is-outlined is-primary is-rounded",
                      on: {
                        click: function ($event) {
                          return _vm.openModal()
                        },
                      },
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.cityId) +
                            ". " +
                            _vm._s(
                              _vm.backmeta.cities[_vm.cityId].nameObject[
                                _vm.currentLanguage
                              ]
                            )
                        ),
                      ]),
                    ]
                  )
                : !_vm.botUrl
                ? _c("strong", { staticClass: "is-size-4" }, [
                    _vm._v(
                      _vm._s(_vm.cityId) +
                        ". " +
                        _vm._s(
                          _vm.backmeta.cities[_vm.cityId].nameObject[
                            _vm.currentLanguage
                          ]
                        )
                    ),
                  ])
                : _c(
                    "b-tooltip",
                    {
                      staticClass: "has-text-centered",
                      attrs: {
                        type: "is-light",
                        triggers: ["click"],
                        "auto-close": ["outside", "escape"],
                        position: "is-right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _c("p", [_vm._v(_vm._s(_vm.tt("NeedTg")))]),
                              _c(
                                "a",
                                {
                                  attrs: { href: _vm.botUrl, target: "_blank" },
                                },
                                [_vm._v("@KarvanClubBot")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "button is-outlined is-primary is-rounded",
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.cityId) +
                                ". " +
                                _vm._s(
                                  _vm.backmeta.cities[_vm.cityId].nameObject[
                                    _vm.currentLanguage
                                  ]
                                )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "level-item" },
            [
              _c("Emoji", {
                key: _vm.cities[_vm.cityId]
                  ? _vm.cities[_vm.cityId].weather.temp
                  : " - - ",
                staticClass: "mt-2 mr-1",
                attrs: { utf: _vm.getCityTempIcon() },
              }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.cities[_vm.cityId]
                      ? _vm.cities[_vm.cityId].weather.temp
                      : " - - "
                  ) +
                  "°C "
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "level-item" },
            [
              _c("Emoji", { staticClass: "mr-1 mt-2", attrs: { utf: "🤝" } }),
              _vm._v(" " + _vm._s(_vm.getCityReputation()) + " "),
            ],
            1
          ),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm.isUserAuth && _vm.showMarketCarousel
          ? [
              _c("b-carousel-list", {
                attrs: {
                  data: _vm.getMarketForCarousel(),
                  "items-to-show": 2,
                  repeat: true,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function (marketItem) {
                        return [
                          _c("div", { staticClass: "box p-1 m-1 mb-1" }, [
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "has-text-centered title is-6 mb-0",
                              },
                              [
                                _c("Emoji", {
                                  staticClass: "mr-1",
                                  attrs: {
                                    utf: _vm.goods[marketItem.slug].emoji,
                                    size: "20px",
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { "vertical-align": "20%" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.goods[marketItem.slug].name[
                                          _vm.currentLanguage
                                        ]
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "level is-mobile" }, [
                              _c("div", { staticClass: "level-left" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "level-item",
                                    staticStyle: { display: "inline-block" },
                                    attrs: { title: _vm.tt("CitySellPrice") },
                                  },
                                  [
                                    _c("Emoji", {
                                      staticClass: "mr-1",
                                      attrs: { utf: "📤", size: "18px" },
                                    }),
                                    _c(
                                      "strong",
                                      {
                                        staticStyle: {
                                          "vertical-align": "15%",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            marketItem.sell > 0
                                              ? "+" + marketItem.sell
                                              : " - - "
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("Emoji", {
                                      attrs: { utf: "💰", size: "20px" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "level-right" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "level-item",
                                    staticStyle: { display: "inline-block" },
                                    attrs: { title: _vm.tt("CityBuyPrice") },
                                  },
                                  [
                                    _c("Emoji", {
                                      staticClass: "mr-1",
                                      attrs: { utf: "📥", size: "18px" },
                                    }),
                                    _c(
                                      "strong",
                                      {
                                        staticStyle: {
                                          "vertical-align": "15%",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            marketItem.buy > 0
                                              ? "-" + marketItem.buy
                                              : " - - "
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("Emoji", {
                                      attrs: { utf: "💰", size: "20px" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2747936094
                ),
                model: {
                  value: _vm.marketCarousel,
                  callback: function ($$v) {
                    _vm.marketCarousel = $$v
                  },
                  expression: "marketCarousel",
                },
              }),
            ]
          : _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.backmeta.cities[_vm.cityId].aboutObject[
                      _vm.currentLanguage
                    ]
                  ) +
                  " "
              ),
            ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }