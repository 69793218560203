var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container is-max-desktop mt-5" },
    [
      _c("div", { staticClass: "section columns" }, [
        _c("div", { staticClass: "column", staticStyle: { padding: "0" } }, [
          _c(
            "a",
            {
              attrs: {
                href:
                  "/" +
                  (_vm.currentLanguage !== "ua" ? _vm.currentLanguage : ""),
              },
            },
            [_c("h1", { staticClass: "title ml-4" }, [_vm._v("KarVan.Club")])]
          ),
          _c("h2", { staticClass: "subtitle ml-4" }, [
            _c("a", { attrs: { href: _vm.getBotUrl(), target: "_blank" } }, [
              _vm._v("@KarvanClubBot"),
            ]),
            _vm._v(" " + _vm._s(_vm.tt("LandingSub")) + " "),
          ]),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showTgInfo || !_vm.isUserAuth,
                expression: "showTgInfo || !isUserAuth",
              },
            ],
            ref: "tgInfoPost",
          }),
          _c(
            "div",
            { staticClass: "buttons is-centered mt-4" },
            [
              _c("a", { attrs: { href: _vm.getBotUrl(), target: "_blank" } }, [
                _c(
                  "button",
                  { staticClass: "button is-rounded is-primary is-light" },
                  [
                    _c("Emoji", {
                      staticClass: "mr-2 mt-1",
                      attrs: { utf: "🐫" },
                    }),
                    _vm._v(" " + _vm._s(_vm.tt("Play")) + " "),
                  ],
                  1
                ),
              ]),
              _vm.isUserAuth
                ? _c(
                    "button",
                    {
                      staticClass: "button is-rounded is-success is-medium",
                      on: {
                        click: function ($event) {
                          return _vm.openMap()
                        },
                      },
                    },
                    [
                      _c("Emoji", {
                        staticClass: "mr-2 mt-2",
                        attrs: { utf: "🌎" },
                      }),
                      _vm._v(" " + _vm._s(_vm.tt("Map")) + " "),
                    ],
                    1
                  )
                : _c(
                    "b-tooltip",
                    {
                      staticClass: "has-text-centered",
                      attrs: {
                        type: "is-light",
                        triggers: ["click"],
                        "auto-close": ["outside", "escape"],
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _c("p", [_vm._v(_vm._s(_vm.tt("NeedTg")))]),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.getBotUrl(),
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("@KarvanClubBot")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button is-rounded is-success is-medium",
                        },
                        [
                          _c("Emoji", {
                            staticClass: "mr-2 mt-2",
                            attrs: { utf: "🌎" },
                          }),
                          _vm._v(" " + _vm._s(_vm.tt("Map")) + " "),
                        ],
                        1
                      ),
                    ]
                  ),
              _c("a", { attrs: { href: "#cities" } }, [
                _c(
                  "button",
                  { staticClass: "button is-rounded" },
                  [
                    _c("Emoji", {
                      key: _vm.cityIcon,
                      staticClass: "mr-2 mt-2",
                      attrs: { utf: _vm.cityIcon },
                    }),
                    _vm._v(" " + _vm._s(_vm.tt("Cities")) + " "),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "buttons is-centered" }, [
            _c("a", { attrs: { href: "#topReputation" } }, [
              _c(
                "button",
                { staticClass: "button is-rounded" },
                [
                  _c("strong", [_vm._v("TOP100")]),
                  _c("Emoji", { staticClass: "mt-1", attrs: { utf: "🤝" } }),
                  _vm._v(_vm._s(_vm.tt("Reputation")) + " "),
                ],
                1
              ),
            ]),
            _vm.isUserAuth
              ? _c(
                  "button",
                  {
                    staticClass: "button is-rounded",
                    class: { "is-ghost": !_vm.showTgInfo },
                    on: {
                      click: function ($event) {
                        return _vm.togleTgInfo()
                      },
                    },
                  },
                  [_c("Emoji", { staticClass: "mt-2", attrs: { utf: "📰" } })],
                  1
                )
              : _vm._e(),
            _c("a", { attrs: { href: "#topRide" } }, [
              _c(
                "button",
                { staticClass: "button is-rounded" },
                [
                  _c("strong", [_vm._v("TOP100")]),
                  _c("Emoji", {
                    staticClass: "mr-1 mt-1",
                    attrs: { utf: "👣" },
                  }),
                  _vm._v(_vm._s(_vm.tt("RideKm")) + " "),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showTgInfo || !_vm.isUserAuth,
                expression: "showTgInfo || !isUserAuth",
              },
            ],
            staticClass: "column",
          },
          _vm._l(_vm.newsPosts, function (post, index) {
            return _c("div", {
              key: index,
              ref: "tgNews" + index,
              refInFor: true,
            })
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "section container mb-4" }, [
        _c(
          "h1",
          { staticClass: "title has-text-centered" },
          [
            _c("Emoji", {
              staticClass: "mr-1",
              attrs: { utf: "🚘", size: "20px" },
            }),
            _c("span", [_vm._v(_vm._s(_vm.tt("Transport")))]),
          ],
          1
        ),
        _c("h2", { staticClass: "subtitle has-text-centered" }, [
          _vm._v(_vm._s(_vm.tt("TransportInfo"))),
        ]),
        _c(
          "div",
          { staticClass: "columns is-multiline is-centered" },
          _vm._l(_vm.backmeta.transport, function (transport, transportSlug) {
            return _c(
              "div",
              {
                key: _vm.userTransport[transportSlug]
                  ? _vm.userTransport[transportSlug].id
                  : transportSlug,
                staticClass: "column is-6",
              },
              [
                _c("Transport", {
                  attrs: {
                    slug: transportSlug,
                    transportId: _vm.userTransport[transportSlug]
                      ? _vm.userTransport[transportSlug].id
                      : null,
                    botUrl: _vm.getBotUrl(),
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "section container", attrs: { id: "cities" } }, [
        _c(
          "h1",
          { staticClass: "title has-text-centered" },
          [
            _c("Emoji", {
              key: _vm.cityIcon,
              staticClass: "mr-1",
              attrs: { utf: _vm.cityIcon, size: "20px" },
            }),
            _c("span", [_vm._v(_vm._s(_vm.tt("Cities")))]),
            _vm._m(0),
          ],
          1
        ),
        _c("h2", { staticClass: "subtitle has-text-centered" }, [
          _vm._v(_vm._s(_vm.tt("CityInfo"))),
        ]),
        _c(
          "div",
          { staticClass: "columns is-multiline is-centered" },
          _vm._l(_vm.backmeta.cities, function (city, cityId) {
            return _c(
              "div",
              { key: "city" + cityId, staticClass: "column is-6" },
              [
                _c("City", {
                  key: "cityElement" + cityId,
                  attrs: { cityId: cityId, botUrl: _vm.getBotUrl() },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c("CityModal"),
      _c(
        "div",
        {
          staticClass: "section columns",
          staticStyle: { "padding-top": "2em" },
        },
        [
          _c("div", { staticClass: "column", attrs: { id: "topReputation" } }, [
            _c(
              "h1",
              { staticClass: "title has-text-centered" },
              [
                _c("span", { staticStyle: { "vertical-align": "10%" } }, [
                  _vm._v("TOP100 "),
                ]),
                _c("Emoji", { attrs: { utf: "🤝", size: "24px" } }),
                _c("span", { staticStyle: { "vertical-align": "15%" } }, [
                  _vm._v(" " + _vm._s(_vm.tt("Reputation"))),
                ]),
                _vm._m(1),
              ],
              1
            ),
            _c("h2", { staticClass: "subtitle has-text-centered" }, [
              _vm._v("WIP"),
            ]),
          ]),
          _c("div", { staticClass: "column", attrs: { id: "topRide" } }, [
            _c(
              "h1",
              { staticClass: "title has-text-centered" },
              [
                _c("span", [_vm._v("TOP100 ")]),
                _c("Emoji", { attrs: { utf: "👣", size: "24px" } }),
                _c("span", [_vm._v(" " + _vm._s(_vm.tt("RideKm")))]),
                _vm._m(2),
              ],
              1
            ),
            _c("h2", { staticClass: "subtitle has-text-centered" }, [
              _vm._v("WIP"),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "section columns is-mobile" }, [
        _c("div", { staticClass: "column ml-2 is-size-7" }, [
          _vm._m(3),
          _c("p", [
            _c("a", { attrs: { target: "_blank", href: _vm.getBotUrl() } }, [
              _vm._v("@KarvanClubBot "),
            ]),
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: "https://t.me/KarvanClubNews",
                },
              },
              [_vm._v("@KarvanClubNews")]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "column has-text-right mt-3 mr-2 is-size-5" },
          [
            _c("a", { staticClass: "mr-4", attrs: { href: "/" } }, [
              _vm.currentLanguage == "ua"
                ? _c("strong", [_vm._v("UA")])
                : _c("span", [_vm._v("UA")]),
            ]),
            _c("a", { staticClass: "mr-4", attrs: { href: "/en" } }, [
              _vm.currentLanguage == "en"
                ? _c("strong", [_vm._v("EN")])
                : _c("span", [_vm._v("EN")]),
            ]),
            _c("a", { attrs: { href: "/ru" } }, [
              _vm.currentLanguage == "ru"
                ? _c("strong", [_vm._v("RU")])
                : _c("span", [_vm._v("RU")]),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "#" } }, [
      _c("button", { staticClass: "button is-ghost" }, [_vm._v("ᛏ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "#" } }, [
      _c("button", { staticClass: "button is-ghost" }, [_vm._v("ᛏ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "#" } }, [
      _c("button", { staticClass: "button is-ghost" }, [_vm._v("ᛏ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("strong", {}, [_vm._v("© 2024 KarVan.Club")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }