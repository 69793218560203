var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", {
    style: "font-size:" + _vm.size,
    domProps: { innerHTML: _vm._s(_vm.realElement) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }